import React, { useContext, useState, useEffect } from 'react';
import context from '../context';
import styled from 'styled-components';
import mobileLogo from '../images/logo-light-mobil.png';

const Logo = styled.img`
  min-width: 10px;
  min-height: 10px;
  max-width: ${({ isScrolled }) => (isScrolled ? '100px' : '150px')};
  max-height: ${({ isScrolled }) => (isScrolled ? '100px' : '150px')};
  position: absolute;
  object-fit: contain;
  object-position: center;
  transition: all 0.3s ease;
`;

export default ({ dark, mobile }) => {
  const state = useContext(context);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Logo 
      src={dark ? state.logoDark : mobile ? mobileLogo : state.logo} 
      alt="Logo" 
      isScrolled={isScrolled} 
    />
  );
};

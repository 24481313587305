import React, { useContext, useState, useEffect } from 'react';
import context from '../context';
import styled from 'styled-components';
import mobileLogo from '../images/logo-light-mobil.png';

const Logo = styled.img`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin-top: 8rem;
  object-fit: contain;
  object-position: left;
  transition: width 0.3s ease, height 0.3s ease;
`;

export default ({ dark, mobile }) => {
  const state = useContext(context);
  const isHome = window.location.pathname === '/';
  const [size, setSize] = useState(isHome ? 250 : 150);

  useEffect(() => {
    setSize(isHome ? 250 : 150); // Cambiar tamaño instantáneamente al cambiar de página
  }, [isHome]);

  return (
    <Logo
      src={dark ? state.logoDark : mobile ? mobileLogo : state.logo}
      alt="Logo"
      size={size}
    />
  );
};

export default (data) => {
  return {
    builderId: data._id ? data._id : "hunterpropiedades",
    typeId: data.user ? "user" : "office",
    officeId: data.user ? data.user : data.office ? data.office : "hunterpropiedades",
    primaryColor: data.primaryColor ? data.primaryColor : "#161616", // Negro
    address: data.address ? data.address : "LAS CONDES - SANTIAGO",
    email: data.email ? data.email : "laurap@hunterpropiedades.cl",
    facebook: data.facebook ? data.facebook : "https://facebook.com/Hunterpropiedades",
    twitter: data.twitter ? data.twitter : "https://twitter.com",
    instagram: data.instagram ? data.instagram : "https://instagram.com/hunterpropiedades",
    linkedin: data.linkedin ? data.linkedin : "https://linkedin.com/company/HunterPropiedades",
    favicon: data.favicon ? data.favicon : require("../images/icon.png"),
    lat: data.lat ? data.lat : "-33.410167",
    lng: data.lng ? data.lng : "-70.655265",
    logo: data.logo ? data.logo : require("../images/logo-light.png"),
    logoDark: data.logoDark ? data.logoDark : require("../images/logo-dark.png"),
    movil: data.movil ? data.movil : "+56 9 9873 5816",
    phone: data.phone ? data.phone : "+56 9 9873 5816",
    footerText: data.footerText ? data.footerText : "Hunter Propiedades - Tu aliado en cada paso del camino inmobiliario.",
    template: data.template ? data.template : "",
    home: {
      hero: {
        background: data.home && data.home.hero && data.home.hero.background
          ? data.home.hero.background
          : require("../images/template-home-hero-background.jpg"),
        title: data.home && data.home.hero && data.home.hero.title
          ? data.home.hero.title
          : "HUNTER PROPIEDADES, PROFESIONALISMO Y CERCANÍA PARA CONECTAR PERSONAS CON PROPIEDADES EXCLUSIVAS ACOMPAÑANDOTE EN TODA LA GESTIÓN."
      },
      properties: {
        title: data.home && data.home.properties && data.home.properties.title
          ? data.home.properties.title
          : "Venta y Arriendo de Propiedades",
        maxProperties: data.home && data.home.properties && data.home.properties.maxProperties
          ? data.home.properties.maxProperties
          : 9,
        footer: data.home && data.home.properties && data.home.properties.footer
          ? data.home.properties.footer
          : "Descubre nuestras propiedades disponibles para venta y arriendo.",
        buttonText: data.home && data.home.properties && data.home.properties.buttonText
          ? data.home.properties.buttonText
          : "Ver más"
      },
      about: {
        banner: {
          image: data.home && data.home.about && data.home.about.banner && data.home.about.banner.image
            ? data.home.about.banner.image
            : require("../images/template-home-about-hero-background.jpg"),
          title: data.home && data.home.about && data.home.about.banner && data.home.about.banner.title
            ? data.home.about.banner.title
            : "Descubre la experiencia Hunter Propiedades",
          subTitle: data.home && data.home.about && data.home.about.banner && data.home.about.banner.subTitle
            ? data.home.about.banner.subTitle
            : "Acompañamos a cada cliente con cercanía, empatía y compromiso.",
          buttonText: data.home && data.home.about && data.home.about.banner && data.home.about.banner.buttonText
            ? data.home.about.banner.buttonText
            : "Conócenos"
        }
      },
      services: {
        items: [
          {
            title: "Propiedades para expatriados y ejecutivos",
            description:
              "Brindamos asesoramiento en la búsqueda de viviendas y oficinas según las necesidades de cada cliente. Nos encargamos de la gestión de contratos de alquiler y compra-venta, así como de la asistencia en trámites de mudanza, documentación y adaptación a la ciudad."
          },
          {
            title: "Mercado de lujo y propiedades exclusivas",
            description:
              "Ofrecemos una atención personalizada con total confidencialidad en operaciones de alto valor. Nos especializamos en clientes internacionales y en la comercialización de propiedades premium con estrategias de marketing especializadas."
          },
          {
            title: "Inversiones inmobiliarias",
            description:
              "Asesoramos a clientes interesados en el mercado inmobiliario como inversión. Realizamos análisis de rentabilidad y tendencias del mercado, y gestionamos propiedades para maximizar su valor y retorno."
          },
          {
            title: "Compra y Venta de Propiedades",
            description:
              "Brindamos asesoramiento integral en la compra y venta de inmuebles residenciales y comerciales. Evaluamos el mercado para definir estrategias de precio, negociamos con transparencia y acompañamos legalmente en todo el proceso."
          },
          {
            title: "Alquiler de Propiedades",
            description:
              "Gestionamos alquileres de corto y largo plazo para viviendas, oficinas y locales comerciales. Nos encargamos de la selección de inquilinos, contratos de arrendamiento y administración eficiente de propiedades."
          },
          {
            title: "Relocation & Asesoría para Expatriados",
            description:
              "Apoyamos a ejecutivos y sus familias en la búsqueda de viviendas, adaptación a la ciudad, información sobre colegios y trámites de arriendo o compra de inmuebles."
          },
          {
            title: "Marketing Inmobiliario y Home Staging",
            description:
              "Impulsamos la visibilidad de las propiedades con fotografía profesional, marketing digital y home staging, acelerando la venta o alquiler de inmuebles."
          }
        ]
      },
      reviews: {
        items: data.home && data.home.reviews && data.home.reviews.items.length ? data.home.reviews.items : [
          {
            id: null,
            src:"/team-1.png",
            review: "Son un gran equipo de profesionales. Me han entregado todas las herramientas para dedicarme a lo realmente importante, que es entender las necesidades de mis clientes.",
            author: "Diego Valdenegro"
          },
          {
            id: null,
            src:"/team-2.jpg",
            review: "Muy eficientes. Es impresionante lo rápido que gestionaron mi proceso de venta y me asesoraron perfectamente en todo momento.",
            author: "Felipe Godoy"
          },
          {
            id: null,
            src:"/team-3.jpg",
            review: "¡Me ha parecido fantástico! Me han entregado una valorización gratuita en cosa de horas y con eso pudimos determinar el precio de venta de mi departamento. Realmente te hacen la vida más fácil.",
            author: "Linda Bellone"
          },
          {
            id: null,
            src:"/team-4.jpg",
            review: "Muy buena experiencia. He logrado concretar la venta de mis propiedades en tiempo record, mis clientes están felices!",
            author: "Romina Marschhausen"
          },
          {
            id: null,
            src:"/team-5.jpg",
            review: "El equipo es excelente, me ayudaron a solucionar todas mis dudas y a usar la plataforma. Desde que soy parte del equipo, mi negocio ha mejorado considerablemente.",
            author: "Constanza Viveros"
          },                        
          {
            id: null,
            src:"/team-6.jpg",
            review: "Excelente grupo de profesionales. Mi familia y yo estamos muy felices de haber dejado nuestra propiedad en sus manos. ¡Muchas gracias!",
            author: "Igor Venegas"
          },                                    
        ]
      }       
    },
    about: {
      hero: {
        background: data.about && data.about.hero && data.about.hero.background
          ? data.about.hero.background
          : require("../images/template-about-hero-background.jpg"),
        title: data.about && data.about.hero && data.about.hero.title
          ? data.about.hero.title
          : "Un equipo de profesionales especializados en el sector inmobiliario."
      },
      history: {
        background: data.about && data.about.history && data.about.history.background
          ? data.about.history.background
          : require("../images/template-about-history-background.jpg"),
        description: data.about && data.about.history && data.about.history.description
          ? data.about.history.description
          : `<p>Hunter Propiedades nació con una misión clara: acompañar a cada cliente en la búsqueda del lugar ideal con cercanía, empatía y compromiso. Entendemos que una propiedad no es solo un espacio, sino el escenario donde se construyen nuevas historias, se crean recuerdos y se cumplen sueños.</p>
             <p>Con años de experiencia en el mundo inmobiliario y en relocation, sabemos que cada cliente es único. Por eso, nuestro enfoque es personalizado y humano, escuchando tus necesidades y guiándote en cada paso del proceso, ya sea para comprar, vender o alquilar. En Hunter Propiedades, combinamos intuición y precisión para encontrar oportunidades que realmente encajen contigo. Más que una inmobiliaria, somos tu aliado en esta nueva etapa, ayudándote a tomar decisiones con confianza y tranquilidad. Porque tu hogar no es cualquier lugar, es tu lugar. Y estamos aquí para ayudarte a encontrarlo.</p>`,
        title: data.about && data.about.history && data.about.history.title
          ? data.about.history.title
          : "Nuestra Historia"
      },
      description: {
        items: data.about && data.about.description && data.about.description.items && data.about.description.items.length
          ? data.about.description.items
          : [
              {
                title: "Misión",
                description:
                  "En Hunter Propiedades, nuestra misión es brindar un servicio inmobiliario personalizado, basado en la empatía, la confianza y la excelencia. Nos enfocamos en acompañar a nuestros clientes en cada paso de su búsqueda, garantizando una experiencia cercana y sin complicaciones. Más que vender propiedades, nos comprometemos a encontrar hogares y oportunidades que realmente hagan la diferencia en la vida de quienes confían en nosotros."
              },
              {
                title: "Visión",
                description:
                  "Ser una inmobiliaria de referencia reconocida por su trato humano, transparencia y capacidad para conectar personas con el espacio perfecto para su estilo de vida. Aspiramos a crecer de la mano de nuestros clientes, innovando en el sector inmobiliario y ofreciendo un servicio cada vez más cercano, eficiente y alineado con las necesidades del mercado."
              }
            ]
      },
      stats: {
        proffesionals: data.about && data.about.stats && data.about.stats.proffesionals
          ? data.about.stats.proffesionals
          : 25,
        properties: data.about && data.about.stats && data.about.stats.properties
          ? data.about.stats.properties
          : 200,
        years: data.about && data.about.stats && data.about.stats.years
          ? data.about.stats.years
          : 10,
        transactions: data.about && data.about.stats && data.about.stats.transactions
          ? data.about.stats.transactions
          : 350
      },
      team: {
        visible: true,
        items: data.about && data.about.team && data.about.team.items && data.about.team.items.length
          ? data.about.team.items
          : [
              {
                avatar: require("../images/template-team-member-1.jpg"),
                cv: "Experto en el sector inmobiliario con amplia experiencia en gestión de propiedades y asesoría personalizada.",
                email: "contacto@hunterpropiedades.cl",
                fullName: "Equipo Hunter 1",
                phone: "+56998735816"
              },
              {
                avatar: require("../images/template-team-member-2.jpg"),
                cv: "Profesional comprometido con la excelencia y la atención personalizada en cada operación inmobiliaria.",
                email: "contacto@hunterpropiedades.cl",
                fullName: "Equipo Hunter 2",
                phone: "+56998735816"
              },
              {
                avatar: require("../images/template-team-member-3.jpg"),
                cv: "Especialista en el mercado de lujo y propiedades exclusivas, ofreciendo un servicio de alto valor.",
                email: "contacto@hunterpropiedades.cl",
                fullName: "Equipo Hunter 3",
                phone: "+56998735816"
              },
              {
                avatar: "",
                cv: "Profesional en inversiones inmobiliarias, comprometido en maximizar el valor y retorno de las propiedades.",
                email: "contacto@hunterpropiedades.cl",
                fullName: "Equipo Hunter 4",
                phone: "+56998735816"
              }
            ]
      },
      ubication: {
        title: data.about && data.about.ubication && data.about.ubication.title
          ? data.about.ubication.title
          : "¿Necesitas vender, arrendar o comprar una propiedad? Visítanos en LAS CONDES - SANTIAGO"
      }
    },
    contact: {
      map: {
        title: data.contact && data.contact.map && data.contact.map.title
          ? data.contact.map.title
          : "Encuéntranos en LAS CONDES - SANTIAGO",
        subTitle: data.contact && data.contact.map && data.contact.map.subTitle
          ? data.contact.map.subTitle
          : "Nuestra ubicación estratégica"
      },
      title: data.contact && data.contact.title
        ? data.contact.title
        : "¿Dudas? ¿Consultas? Estamos aquí para ayudarte.",
      subTitle: data.contact && data.contact.subTitle
        ? data.contact.subTitle
        : "Envíanos un mensaje y uno de nuestros asesores se pondrá en contacto contigo a la brevedad."
    }
  }
}

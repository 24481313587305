import React from 'react';
import styled from 'styled-components';

const StyledNavLink = styled.span`
  color: ${({ isHome }) => (isHome ? '#fff' : '#000')};
  text-decoration: none;
  transition: 250ms ease;
  margin-left: ${props => props.first ? "0" : "1rem"};

  &:visited {
    color: ${({ isHome }) => (isHome ? '#fff' : '#000')};
  }

  &:hover {
    color: ${({ isHome }) => (isHome ? '#ccc' : '#333')};
  }  
  
  @media(min-width: 992px) {
    margin-left: ${props => props.first ? "0" : "2rem"};
    color: ${({ isHome }) => (isHome ? 'rgba(255, 255, 255, 0.7)' : '#000')};

    &:hover {
      color: ${({ isHome }) => (isHome ? '#ccc' : '#333')};
    }
  }
`;

const NavLink = ({ children, ...props }) => {
  const isHome = window.location.pathname === '/';
  return <StyledNavLink isHome={isHome} {...props}>{children}</StyledNavLink>;
};

export default NavLink;

import React, { useContext, useState, useEffect } from 'react';
import context from '../context';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import Link from "../components/link";

import RateBar from './ratebar';
import Logo from './logo';
import { NavLink, NavButton } from '../styled-components';

const Header = styled.header`
  background-color: ${({ isHome }) => (isHome ? 'transparent' : '#fff')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease;
`;

const Navigation = styled.nav`
  padding: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: ${props => props.horizontal ? "flex" : "block"};
  text-align: center;
`;

const NavItem = styled.li``;

export default ({ dark })=> {
  const state = useContext(context);
  const [isHome, setIsHome] = useState(window.location.pathname === '/');

  useEffect(() => {
    const detectUrlChange = () => {
      setIsHome(window.location.pathname === '/');
    };

    window.addEventListener('popstate', detectUrlChange);
    window.addEventListener('pushState', detectUrlChange);
    window.addEventListener('replaceState', detectUrlChange);

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function(...args) {
      originalPushState.apply(window.history, args);
      window.dispatchEvent(new Event('pushState'));
    };

    window.history.replaceState = function(...args) {
      originalReplaceState.apply(window.history, args);
      window.dispatchEvent(new Event('replaceState'));
    };

    return () => {
      window.removeEventListener('popstate', detectUrlChange);
      window.removeEventListener('pushState', detectUrlChange);
      window.removeEventListener('replaceState', detectUrlChange);
    };
  }, []);

  return(
    <Header className="d-none d-lg-block" isHome={isHome}>
      <Container>
        <Navigation>
          <Link to="/">
            <Logo dark={dark} light={!dark} />
          </Link>
          <NavList horizontal>
            <NavItem><Link to="/about"><NavLink dark>Nosotros</NavLink></Link></NavItem>
            <NavItem><Link to="/properties"><NavLink dark>Propiedades</NavLink></Link></NavItem>
            <NavItem><Link to="/how-much"><NavLink dark>¿Cuánto vale tu propiedad?</NavLink></Link></NavItem>
            <NavItem><Link to="/contact"><NavLink dark>Contacto</NavLink></Link></NavItem>
          </NavList>
        </Navigation>
      </Container>
    </Header>
  );
}
